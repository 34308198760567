.rooms-gallery {
	overflow: hidden;
	padding-top: 201px;
	padding-bottom: 223px;
	@include tabletMaxi {
		padding-top: 100px;
		padding-bottom: 158px;
	}
	@include mobile {
		padding-top: 124px;
		padding-bottom: 171px;
	}
}

.rooms-gallery--activities {
	padding-top: 209px;
    padding-bottom: 190px;
	@include tabletMaxi {
		padding-top: 193px;
		padding-bottom: 151px;
	}
	@include mobile {
		padding-top: 147px;
		padding-bottom: 176px;
	}
}

.rooms-gallery--events {
	padding-top: 454px;
	padding-bottom: 246px;
	@include tabletMaxi {
		padding-top: 443px;
		padding-bottom: 228px;
	}
	@include mobile {
		padding-top: 253px;
		padding-bottom: 190px;
	}
}

.rooms-gallery__wrapper {
	padding: 0 $wrap-desk-md;
	@include tabletMaxi {
		padding: 0 $wrap-tab-lg;
	}
	@include mobile {
		padding: 0 $wrap-mob-md;
	}
}

.rooms-gallery__container {
	@extend %container;
	position: relative;
	max-width: 1420px;
}

.rooms-gallery__decor-text {
	position: absolute;
	z-index: 30;
	left: percentage(68/1420);
	top: -291px;
	opacity: .06;
	font-family: $font-accent;
	font-size: 380px;
	line-height: 1.325;
	font-weight: 200;
	white-space: nowrap;
	@include tabletMaxi {
		left: percentage(70/814);
		top: -85px;
		font-size: 320px;
	}
	@include mobile {
		left: -$wrap-mob-md;
		top: -182px;
		font-size: 220px;
	}
}

.rooms-gallery__decor-text--activities {
	@include onlyTabletMaxi {
		left: percentage(-58/814);
		top: -240px;
		font-size: 290px;
	}
	@include mobile {
		left: -40px;
		top: -203px;
	}
}

.rooms-gallery__decor-text--events {
	left: percentage(89/1420);
	top: -464px;
	font-size: 320px;
	@include tabletMaxi {
		left: percentage(-53/814);
		top: -355px;
		font-size: 220px;
	}
	@include mobile {
		left: -38px;
		top: -308px;
	}
}

.rooms-gallery {
	.svg-logo-plant {
		position: absolute;
		z-index: 10;
		left: percentage(-785/1420);
		top: -99px;
		max-width: none;
		width: percentage(1337/1420);
		transform: rotate(10deg);
		opacity: 0.03;
		@include tabletMaxi {
			left: percentage(-406/814);
			top: 210px;
			width: percentage(865/814);
		}
		@include mobile {
			display: none;
		}
	}
}
