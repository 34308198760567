.ads-banner {
	overflow: hidden;
	height: 46px;
	padding: 0 17px 0 27px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $c-accent;
	text-align: center;
	font-family: $font-accent;
	font-size: 19px;
	font-weight: 200;
	color: $c-dark;
	transition: height $head-trans;
	@include tabletMaxi {
		padding: 0 20px 0 30px;
	}
	@include mobile {
		height: 40px;
		padding: 0 14px 0 24px;
		font-size: 15px;
	}

	&::before {
		content: "";
	}

	.header--menu-opened &,
	.header--scrolled & {
		height: 0;
	}
}

.ads-banner__close {
	position: relative;
	cursor: pointer;

	&::before {
		content: "";
		position: absolute;
		left: -10px;
		top: -10px;
		width: calc(100% + 20px);
		height: calc(100% + 20px);
	}

	svg {
		width: 10px;
		height: 10px;
	}
}
