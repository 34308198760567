.hero-level-1 {
	position: relative;
	overflow: hidden;
	padding: 0 $wrap-desk-sm;
	@include tabletMaxi {
		padding: 0 $wrap-tab-md;
	}
	@include mobile {
		padding: 0 $wrap-mob-md;
	}
}

.hero-level-1--stay {
	padding-top: 359px;
	padding-bottom: 411px;
	@include tabletMaxi {
		padding-top: 370px;
		padding-bottom: 336px;
	}
	@include mobile {
		padding-top: 340px;
		padding-bottom: 250px;
	}
}

.hero-level-1--dine {
	padding-top: 373px;
	padding-bottom: 564px;
	@include tabletMaxi {
		padding-top: 358px;
		padding-bottom: 413px;
	}
	@include mobile {
		padding-top: 339px;
		padding-bottom: 282px;
	}
}

.hero-level-1--events {
	padding-top: 398px;
	padding-bottom: 439px;
	@include tabletMaxi {
		padding-top: 347px;
		padding-bottom: 340px;
	}
	@include mobile {
		padding-top: 311px;
		padding-bottom: 246px;
	}
}

.hero-level-1__container {
	@extend %container;
	position: relative;
	z-index: 50;
	max-width: 1460px;
}

.hero-level-1__title {
	font-family: $font-accent;
	font-size: 154px;
	line-height: (152/154);
	font-weight: 200;
	@include laptop {
		font-size: 132px;
	}
	@include tabletMaxi {
		font-size: vw(104/1024);
		line-height: (96/104);
	}
	@include tablet {
		font-size: vw(73/768);
	}
	@include mobile {
		font-size: vw(50/414);
		line-height: (53/47);
	}

	span {
		display: block;
	}
}

.hero-level-1__title--stay {
	margin-bottom: 61px;
	@include mobile {
		margin-bottom: 16px;
		font-size: vw(47/414);
	}

	span {
		&:first-child {
			padding-left: 10px;
			@include laptop {
				padding-left: 0;
			}
			@include onlyTabletMaxi {
				padding-right: 16px;
				text-align: right;
			}
			@include tablet {
				padding-right: 0;
			}
			@include mobile {
				margin-left: -4px;
			}
		}

		&:last-child {
			padding-right: percentage(165/1460);
			text-align: right;
			@include laptop {
				padding-right: 0;
			}
			@include tabletMaxi {
				padding-right: 18px;
			}
			@include tablet {
				padding-right: 0;
			}
		}
	}
}

.hero-level-1__title--dine {
	margin-bottom: 69px;
	padding-left: 40px;
	padding-right: 70px;
	@include tabletMaxi {
		margin-bottom: 57px;
		padding: 0;
		line-height: 1;
	}
	@include mobile {
		margin-bottom: 16px;
		line-height: (54/50);
	}

	span {
		&:first-child {
			@include upTablet {
				text-align: right;
			}
			@include mobile {
				margin-left: -4px;
			}
		}

		&:last-child {
			@include mobile {
				text-align: right;
			}
		}
	}
}

.hero-level-1__title--events {
	margin-left: 22px;
	font-size: 300px;
	@include tabletMaxi {
		margin-left: -11px;
		font-size: 230px;
	}
	@include mobile {
		margin-left: 0;
		text-align: center;
		font-size: 90px;
	}
}

.hero-level-1__txt {
	@extend %txt;
	width: percentage(620/1460);
	font-family: $font-accent;
	font-size: 32px;
	line-height: 1.325;
	font-weight: 200;
	@include tabletMaxi {
		width: percentage(436/854);
		font-size: 24px;
		line-height: (38/24);
	}
	@include mobile {
		width: 100%;
		font-size: 22px;
		line-height: (36/22);
	}
}

.hero-level-1__txt--stay {
	@include upLaptop {
		margin-left: percentage(720/1460);
	}
	@include onlyTabletMaxi {
		margin-left: auto;
		margin-right: -15px;
	}
}

.hero-level-1__txt--dine {
	@include upLaptop {
		margin-left: percentage(180/1460);
		font-size: 34px;
	}
	@include onlyTabletMaxi {
		margin-left: 4px;
	}
	@include mobile {
		margin-left: 0;
	}
}

[class*=hero-level-1__img] {
	position: absolute;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.hero-level-1__image-wrapper {
	@include absolute-full-width;
	background-color: $c-dark;
}

.hero-level-1__img-1-stay {
	right: 0;
	top: 157px;
	width: percentage(400/1620);
	@include aspect-ratio(543, 400);
	@include tabletMaxi {
		top: 171px;
		width: percentage(335/1024);
		@include aspect-ratio(415, 335);
	}
	@include mobile {
		top: 165px;
		width: percentage(131/414);
		@include aspect-ratio(173, 131);
	}

	img {
		opacity: .5;
		@include onlyTabletMaxi {
			opacity: .7;
		}
	}
}

.hero-level-1__img-2-stay {
	left: 0;
	top: 319px;
	width: percentage(400/1620);
	@include aspect-ratio(565, 400);
	@include tabletMaxi {
		top: 285px;
		width: percentage(335/1024);
		@include aspect-ratio(473, 335);
	}
	@include mobile {
		top: 219px;
		width: percentage(197/414);
		@include aspect-ratio(340, 197);
	}

	img {
		opacity: .6;
		@include tabletMaxi {
			opacity: .7;
		}
		@include mobile {
			opacity: .5;
		}
	}
}

.hero-level-1__img-3-stay {
	left: percentage(500/1620);
	bottom: 238px;
	width: percentage(218/1620);
	@include aspect-ratio(291, 218);
	@include tabletMaxi {
		left: percentage(271/1024);
		bottom: 115px;
		width: percentage(147/1024);
		@include aspect-ratio(196, 147);
	}
	@include mobile {
		left: auto;
		right: $wrap-mob-md;
		bottom: 71px;
		width: percentage(176/414);
		@include aspect-ratio(218, 176);
	}

	img {
		opacity: .5;
		@include onlyTabletMaxi {
			opacity: .7;
		}
	}
}

.hero-level-1__img-1-dine {
	left: percentage(100/1620);
	top: 380px;
	width: percentage(220/1620);
	@include aspect-ratio(290, 220);
	@include tabletMaxi {
		left: $wrap-tab-lg;
		top: 213px;
		width: percentage(231/1024);
		@include aspect-ratio(305, 231);
	}
	@include mobile {
		left: 0;
		top: 196px;
		width: percentage(131/414);
		@include aspect-ratio(173, 131);
	}

	img {
		opacity: .5;
	}
}

.hero-level-1__img-2-dine {
	right: 0;
	bottom: 258px;
	width: percentage(560/1620);
	@include aspect-ratio(684, 560);
	@include tabletMaxi {
		bottom: 202px;
		width: percentage(416/1024);
		@include aspect-ratio(509, 416);
	}
	@include mobile {
		right: percentage(-108/414);
		bottom: auto;
		top: 259px;
		width: percentage(305/414);
		@include aspect-ratio(340, 305);
	}

	img {
		opacity: .6;
		@include mobile {
			opacity: .4;
		}
	}
}

.hero-level-1__img-3-dine {
	right: percentage(399/1620);
	bottom: 150px;
	width: percentage(320/1620);
	@include aspect-ratio(380, 320);
	@include tabletMaxi {
		right: percentage(271/1024);
		bottom: 92px;
		width: percentage(231/1024);
		@include aspect-ratio(274, 231);
	}
	@include mobile {
		left: percentage(86/414);
		right: auto;
		bottom: 95px;
		width: percentage(111/414);
		@include aspect-ratio(138, 111);
	}

	img {
		opacity: .7;
		@include mobile {
			opacity: .6;
		}
	}
}

.hero-level-1__img-1-events {
	left: 0;
	bottom: 127px;
	width: percentage(480/1620);
	@include aspect-ratio(678, 480);
	@include upDesktopMaxi {
		left: 15%;
		width: 20%;
	}
	@include tabletMaxi {
		bottom: 118px;
		width: percentage(335/1024);
		@include aspect-ratio(474, 335);
	}
	@include tablet {
		width: 35.7%;
	}
	@include mobile {
		bottom: 126px;
		width: percentage(131/414);
		@include aspect-ratio(186, 131);
	}

	img {
		opacity: .6;
	}
}

.hero-level-1__img-2-events {
	right: 0;
	top: 170px;
	width: percentage(560/1620);
	@include aspect-ratio(760, 560);
	@include upDesktopMaxi {
		right: 15%;
		width: 23%;
	}
	@include tabletMaxi {
		top: 185px;
		width: percentage(335/1024);
		@include aspect-ratio(455, 335);
	}
	@include tablet {
		width: 38.7%;
	}
	@include mobile {
		top: 183px;
		width: percentage(197/414);
		@include aspect-ratio(267, 197);
	}

	img {
		opacity: .6;
	}
}

.hero-level-1__img-3-events {
	left: percentage(660/1620);
	bottom: 0;
	width: percentage(220/1620);
	@include aspect-ratio(294, 220);
	@include upDesktopMaxi {
		width: 9%;
	}
	@include tabletMaxi {
		left: percentage(438/1024);
		width: percentage(148/1024);
		@include aspect-ratio(198, 148);
	}
	@include tablet {
		width: 17.5%;
	}
	@include mobile {
		left: percentage(217/414);
		width: percentage(111/414);
		@include aspect-ratio(148, 111);
	}

	img {
		opacity: .6;
	}
}
