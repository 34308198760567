.description {
	position: relative;
	overflow: hidden;
	padding: 348px $wrap-desk-md 419px $wrap-desk-lg;
	background-color: $c-beige;
	color: $c-dark;
	@include tabletMaxi {
		padding: 193px 0 341px $wrap-tab-lg;
	}
	@include mobile {
		padding: 298px $wrap-mob-sm 270px;
	}
}

.description__container {
	@extend %container;
	position: relative;
	z-index: 50;
	max-width: 1340px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include mobile {
		flex-direction: column;
	}
}

.description__content {
	margin-bottom: 162px;
	width: percentage(540/1340);
	@include tabletMaxi {
		margin-bottom: 53px;
		width: percentage(398/919);
	}
	@include mobile {
		margin-bottom: 52px;
		width: 100%;
		padding: 0 $wrap-mob-md;
	}
}

.description__title {
	margin-bottom: 21px;
	font-family: $font-accent;
	font-size: 80px;
	line-height: 1.1;
	font-weight: 200;
	@include tabletMaxi {
		margin-bottom: 24px;
		font-size: 48px;
	}
	@include mobile {
		margin-bottom: 19px;
		font-size: 42px;
	}
}

.description__photo-static,
.description__photo-float {
	img {
		@include absolute-full-width;
		object-fit: cover;
	}
}

.description__photo-static {
	position: relative;
	width: percentage(620/1340);
	@include aspect-ratio(780, 620);
	@include tabletMaxi {
		width: percentage(418/919);
		@include aspect-ratio(573, 418);
	}
	@include mobile {
		align-self: flex-end;
		width: percentage(263/414);
		@include aspect-ratio(331, 263);
	}
}

.description__photo-float {
	position: absolute;
	left: percentage(141/1340);
	bottom: 0;
	transform: translateY(#{percentage(256/400)});
	width: percentage(400/1340);
	@include aspect-ratio;
	@include tabletMaxi {
		left: 0;
		transform: translateY(#{percentage(223/283)});
		width: percentage(314/919);
		@include aspect-ratio(283, 314);
	}
	@include tablet {
		transform: translateY(100%);
	}
	@include mobile {
		transform: translateY(#{percentage(133/254)});
		width: percentage(197/414);
		@include aspect-ratio(254, 197);
	}
}

.description__decoration {
	position: absolute;
	left: percentage(51/1620);
	top: 80px;
	font-family: $font-accent;
	font-size: 400px;
	line-height: 1;
	white-space: nowrap;
	font-weight: 200;
	color: rgba($c-black, .05);
	@include tabletMaxi {
		left: percentage(-98/1024);
		top: 12px;
		font-size: 290px;
	}
	@include mobile {
		left: percentage(33/414);
		top: 54px;
		font-size: 220px;
	}
}
