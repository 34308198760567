[data-animation-fadeY] {
	opacity: 0;
	transform: translateY(50px);
}

.js-page-transition {
	opacity: 0;
}

.parallax-down {
	margin-top: -100px;
	@include tablet {
		margin-top: -75px;
	}
	@include mobile {
		margin-top: -50px;
	}
}

.parallax-up {
	margin-top: 100px;
	@include tablet {
		margin-top: 75px;
	}
	@include mobile {
		margin-top: 50px;
	}
}

.parallax-whithin__wrapper {
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

.parallax-whithin__img {
	max-width: none;
	width: 100%;
	height: 120%;
	object-fit: cover;
}

.parallax-hero__wrapper {
	overflow: hidden;
	display: flex;
	align-items: flex-end;
}

.parallax-hero__img {
	max-width: none;
	width: 100%;
	height: 115%;
	object-fit: cover;
}
