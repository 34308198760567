.custom-select {
	position: relative;

	&__name {
		@extend %input-style;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		svg {
			position: relative;
			z-index: 100;
		}
	}

	&__list {
		position: absolute;
		z-index: 50;
		left: 0;
		top: 0;
		width: 100%;
		overflow: hidden;
		box-shadow: inset 0 0 0 1px $c-beige;
		background-color: #50533B;
		text-align: center;
		font-size: 17px;
		line-height: (32/17);
		font-weight: 300;
	}

	&__container {
		height: 100%;
		padding: 21px 0 12px;
	}

	&__item {
		opacity: .4;
		transition: opacity $ease-200, background-color $ease-200;
		cursor: pointer;

		@include hover {
			opacity: 1;
			background-color: rgba($c-dark, .24);
		}

		&--disabled {
			display: none;
		}
	}
}
