.info-blocks {
	position: relative;
	overflow: hidden;
	padding-bottom: 217px;
	@include laptop {
		padding-bottom: 420px;
	}
	@include tabletMaxi {
		padding-bottom: 330px;
	}
	@include mobile {
		padding-bottom: 60px;
	}

	.svg-logo-plant {
		position: absolute;
		right: percentage(-642/1620);
		bottom: 267px;
		max-width: none;
		width: percentage(1786/1620);
		opacity: .03;
		transform: rotate(-40deg);
		@include tabletMaxi {
			right: percentage(-893/1024);
			bottom: 640px;
			width: percentage(1786/1024);
		}
		@include mobile {
			display: none;
		}
	}

	&:not(.info-blocks--neighbourhood) {
		.svg-logo-plant {
			bottom: 117px;
			@include tabletMaxi {
				display: none;
			}
		}
	}
}

.info-blocks--neighbourhood {
	padding-top: 491px;
	@include tabletMaxi {
		padding-top: 386px;
	}
	@include mobile {
		padding-top: 197px;
	}
}
