.error-footer {
	position: relative;
	z-index: 50;
	align-self: flex-start;
	width: 100%;
	border-top: 1px solid rgba($c-white, .2);
	padding: 31px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: $font-accent;
	font-size: 16px;
	line-height: 1.1;
	font-weight: 200;
	@include lowHeightLaptop {
		padding: 24px 0;
	}
	@include onlyTabletMaxi {
		width: calc(100% - 10px);
		padding: 34px 0 39px;
	}
	@include mobile {
		padding: 19px 0 28px;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.error-footer__left {
	@include mobile {
		order: 3;
		margin-top: 9px;
	}
}

.error-footer__center {
	margin-left: percentage(252/1460);
	@include tabletMaxi {
		margin-left: percentage(96/934);
	}
	@include mobile {
		order: 1;
		margin-left: 0;

		&::after {
			content: "\00A0\2022\00A0";
		}
	}
}

.error-footer__right {
	@include mobile {
		order: 2;
	}
}
