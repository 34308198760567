.rooms-more {
	height: 677px;
	display: flex;
	@include tabletMaxi {
		height: 600px;
	}
	@include mobile {
		height: auto;
		flex-direction: column;
	}
}

.rooms-more__photo {
	position: relative;
	width: percentage(802/1620);
	@include tabletMaxi {
		width: percentage(512/1024)
	}
	@include mobile {
		width: 100%;
		@include aspect-ratio(440, 414);
	}

	img {
		@include absolute-full-width;
		object-fit: cover;
	}
}

.rooms-more__info {
	width: percentage(818/1620);
	padding: 35px percentage(180/1620) 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	background-color: $c-green;
	@include tabletMaxi {
		width: percentage(512/1024);
		padding: 0 percentage(80/1024) 0 percentage(95/1024);
	}
	@include mobile {
		width: 100%;
		height: 500px;
		padding: 0 $wrap-mob-md;
	}
}

.rooms-more__title {
	margin-bottom: 22px;
	font-family: $font-accent;
	font-size: 60px;
	font-weight: 200;
	@include tabletMaxi {
		margin-bottom: 25px;
		font-size: 40px;
	}
	@include mobile {
		margin-bottom: 20px;
		font-size: 52px;
	}
}

.rooms-more__txt {
	margin-bottom: 47px;
	@include tabletMaxi {
		margin-bottom: 33px;
	}
	@include mobile {
		margin-bottom: 35px;
	}
}
