%btn {
	display: inline-block;

	&:not(button) {
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}

	span, svg {
		position: relative;
		z-index: 10;
		display: inline-block;
		vertical-align: middle;
	}

	svg {
		fill: currentColor;
	}
}

.link {
	@extend %btn;
	position: relative;
	height: 94px;
	padding-right: 41px;
	font-family: $font-accent;
	font-size: 24px;
	line-height: 1.1;
	font-weight: 400;
	@include tabletMaxi {
		font-size: 22px;
	}

	&::after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		width: 94px;
		height: 100%;
		border-radius: 50%;
		border: 1px dashed currentColor;
		opacity: .3;
		transition: transform $ease-300;
	}

	span {
		margin-bottom: 5px;
		overflow: hidden;

		&::before,
		&::after {
			content: attr(data-content);
			display: block;
			transition: transform $ease-300;
		}

		&::after {
			position: absolute;
			top: 100%;
			left: 0;
		}
	}

	svg {
		margin-left: 18px;
		width: 12px;
		height: 20px;
		@include tabletMaxi {
			margin-left: 27px;
		}
	}

	@include hover {
		&::after {
			transform: rotate(180deg) scale(1.8);
		}

		span {
			&::before,
			&::after {
				transform: translateY(-100%);
			}
		}
	}
}

@mixin linkShort {
	height: 44px;
	padding-right: 17px;
	font-size: 20px;
	font-weight: 200;

	&::after {
		width: 40px;
		border-radius: 0;
		border: none;
		border-bottom: 1px solid currentColor;
		opacity: .4;
	}

	svg {
		margin-left: 22px;
		width: 7px;
		height: 12px;
		@include mobile {
			margin-left: 19px;
		}
	}

	@include hover {
		&::after {
			transform: scale(1.3);
		}
	}
}

.link--short {
	@include linkShort;
}

.link--tablet-short {
	@include tabletMaxi {
		@include linkShort;
	}
}

.btn {
	@extend %btn;
	position: relative;
	width: 228px;
	height: 64px;
	border: 1px solid currentColor;
	padding-left: 20px;
	font-family: $font-accent;
	font-size: 20px;
	line-height: 1.1;
	font-weight: 200;
	transition: background-color $ease-300, color $ease-300, border-color $ease-300;

	&::after {
		content: "";
		position: absolute;
		left: 3px;
		top: -4px;
		width: calc(100% - 6px);
		height: calc(100% + 8px);
		border: 1px solid currentColor;
		transition: background-color $ease-300, border-color $ease-300;
	}

	span {
		margin-bottom: 2px;
	}

	svg {
		margin-left: 12px;
		width: 7px;
		height: 12px;
	}
}

.btn--dark-green {
	color: $c-dark-green;

	@include hover {
		background-color: $c-dark-green;
		color: $c-white;

		&::after {
			background-color: $c-dark-green;
		}
	}
}

.btn--beige {
	color: $c-beige;

	@include hover {
		border-color: $c-beige;
		color: $c-green;

		&::after {
			border-color: $c-beige;
			background-color: $c-beige;
		}
	}
}

.btn-fill {
	@extend %btn;
	width: 260px;
	height: 80px;
	padding-left: 9px;
	font-family: $font-accent;
	font-size: 21px;
	line-height: 1;
	font-weight: 200;
	text-transform: uppercase;
	color: $c-beige;

	svg {
		margin-left: 12px;
		width: 20px;
		height: 18px;
	}
}

.btn-fill--green {
	background-color: $c-green;
}

.btn-fill--light-green {
	background-color: $c-light-green;
}

.circle {
	@extend %btn;
	width: 60px;
	height: 60px;
	border-radius: 50%;

	svg {
		width: 11px;
		height: 11px;
	}
}

.circle--light {
	background-color: rgba($c-white, .1);
	color: $c-beige;
}

.circle--dark {
	background-color: rgba($c-dark-green, .06);
	color: $c-dark;
}

.social-link {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 1px dashed #BFBBA9;
	display: flex;
	justify-content: center;
	align-items: center;
	@include upDesktopMaxi {
		width: 75px;
		height: 75px;
	}

	svg {
		@include upDesktopMaxi {
			transform: scale(1.25);
		}
	}
}

.scroll-down {
	@extend %btn;
	width: 160px;
	height: 160px;
	border-radius: 50%;
	border: 1px dashed currentColor;
	@include lowHeightLaptop {
		width: 120px;
		height: 120px;
	}

	svg {
		width: 38px;
		height: 34px;
		@include lowHeightLaptop {
			width: 29px;
			height: 26px;
		}
	}
}

.slider-nav {
	width: 200px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: $font-accent;
	font-size: 16px;
	line-height: 1.1;
	font-weight: 200;
}

.slider-nav--light {
	background-color: $c-beige;
	color: $c-dark;
}

.slider-nav--green {
	background-color: $c-light-green;
	color: $c-beige;
}

.slider-nav--dark {
	background-color: $c-dark;
	color: $c-beige;
}

.slider-nav__pag {
	margin: 0 19px;
}

.slider-nav__prev,
.slider-nav__next {
	svg {
		width: 20px;
		height: 18px;
		fill: currentColor;
	}
}

.slider-scroll {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: currentColor;
		opacity: .2;
	}

	.swiper-scrollbar-drag {
		width: 150px;
		height: 3px;
		background-color: currentColor;
	}
}

.btn-book {
	@extend %btn;
	position: fixed;
	z-index: map-get($z-index, bookBtn);
	right: 40px;
	bottom: 50px;
	width: 220px;
	height: 220px;
	padding-left: 14px;
	padding-top: 3px;
	font-size: 18px;
	line-height: 1.1;
	font-weight: 400;
	text-transform: uppercase;
	color: $c-dark;
	transition: right $ease-300, bottom $ease-300, opacity $ease-200;
	@include upDesktopMaxi {
		right: 65px;
		bottom: 81px;
		width: 345px;
		height: 345px;
		font-size: 27px;
	}
	@include lowHeightLaptop {
		right: 20px;
		bottom: 20px;
		width: 180px;
		height: 180px;
		font-size: 15px;
	}
	@include tabletMaxi {
		right: 20px;
		bottom: 155px;
	}
	@include tablet {
		bottom: 30px;
	}
	@include mobile {
		right: -24px;
		bottom: -42px;
		width: 176px;
		height: 176px;
	}

	&.js-fade-anim {
		transition: right $ease-300, bottom $ease-300, opacity $ease-600 3.7s;
	}

	&::after {
		@include absolute-pseudo-full;
		border-radius: 50%;
		background-color: $c-accent;
		transition: transform $ease-200;
	}

	span {
		overflow: hidden;

		&::before,
		&::after {
			content: attr(data-content);
			display: block;
			transition: transform $ease-300;
		}

		&::after {
			position: absolute;
			top: 100%;
			left: 0;
		}
	}

	svg {
		margin-left: 4px;
		width: 20px;
		height: 18px;
		transition: transform $ease-300;
		@include upDesktopMaxi {
			width: 31px;
			height: 26px;
		}
		@include lowHeightLaptop {
			width: 15px;
		}
	}

	@include hover {
		&::after {
			transform: scale(.8);
		}

		svg {
			transform: translateX(10px);
		}

		span {
			&::before,
			&::after {
				transform: translateY(-100%);
			}
		}
	}
}

.btn-book--menu-opened {
	@include onlyTabletMaxi {
		right: 40px;
		bottom: 40px;
	}

	&:not(button) {
		flex-direction: column;
	}
}
