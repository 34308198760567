.cursor {
	position: fixed;
	z-index: map-get($z-index, cursor);
	left: 0; // 0 or 1/2 of cursor width
	top: 0; // 0 or 1/2 of cursor height
	width: 0; // size of your cursor
	height: 0; // size of your cursor
	pointer-events: none;
	transition: opacity $ease-200;
}

.cursor--static {
	position: relative;
	left: auto;
	top: auto;
	pointer-events: auto;
}

.cursor__drag {
	position: absolute;
	left: -70px;
	top: -70px;
	width: 140px;
	height: 140px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(10px);
	transition: width $ease-200, height $ease-200, left $ease-200, top $ease-200;

	&::before {
		content: "";
		@include absolute-full-width;
		border-radius: 50%;
		opacity: .3;
		border: 1px dashed $c-beige;
		background-color: rgba(#C4C4C4, .2);
	}

	&:hover,
	&.cursor__drag--active {
		left: -60px;
		top: -60px;
		width: 120px;
		height: 120px;
	}
}

.cursor__caption {
	position: relative;
	z-index: 50;
	text-align: center;
	font-size: 18px;
	font-weight: 400;
	text-transform: uppercase;
}

[class*=cursor__angle] {
	position: absolute;
	top: calc(50% - 10px);
	width: 12px;
	height: 20px;
	transition: opacity $ease-200;

	svg {
		width: 100%;
		height: 100%;
	}

	.cursor__drag:hover &,
	.cursor__drag--active & {
		opacity: 0;
	}
}

.cursor__angle-left {
	right: calc(100% + 17px);
}

.cursor__angle-right {
	left: calc(100% + 17px);
}

.cursor__nav {
	position: absolute;
	left: -80px;
	top: -80px;
	width: 160px;
	height: 160px;
	border-radius: 50%;
	background-color: $c-light-green;
}

.cursor__arrow {
	position: absolute;
	left: calc(50% - 19px);
	top: calc(50% - 17px);

	svg {
		width: 38px;
		height: 34px;
	}
}
