.imaged-info {
	position: relative;

	&:not(:last-child) {
		@include laptop {
			margin-bottom: 420px;
		}
		@include tabletMaxi {
			margin-bottom: 340px;
		}
		@include mobile {
			margin-bottom: 60px;
		}

		&:first-child {
			@include mobile {
				margin-bottom: 46px;
			}
		}

		&:nth-child(2n-1) {
			@include media($b-laptop+1px) {
				margin-bottom: 220px;
			}
		}

		&:nth-child(2n) {
			@include media($b-laptop+1px) {
				margin-bottom: 248px;
			}
		}
	}
}

.imaged-info--full {
	&:not(:last-child) {
		&:nth-child(2n-1) {
			@include media($b-laptop+1px) {
				margin-bottom: 251px;
			}
			@include laptop {
				margin-bottom: 410px;
			}
		}

		&:nth-child(2n) {
			@include media($b-laptop+1px) {
				margin-bottom: 235px;
			}
			@include laptop {
				margin-bottom: 435px;
			}
		}

		&:nth-child(n) {
			@include mobile {
				margin-bottom: 40px;
			}
		}
	}
}

.imaged-info__gallery {
	width: percentage(1120/1620);
	@include laptop {
		width: 83%;
	}
	@include tabletMaxi {
		width: calc(100% - #{$wrap-tab-lg});
	}
	@include mobile {
		width: 100%;
	}

	&:not(.imaged-info__gallery--right) {
		margin-left: 0;
	}

	&.imaged-info__gallery--right {
		margin-right: 0;
	}
}

.imaged-info__photo {
	position: relative;
	@include aspect-ratio(766, 1120);
	transition-property: opacity;
	@include tabletMaxi {
		@include aspect-ratio(700, 920);
	}
	@include mobile {
		@include aspect-ratio(400, 414);
	}

	.imaged-info:first-child &:not(.imaged-info__photo--full) {
		@include onlyTabletMaxi {
			@include aspect-ratio(780, 920);
		}
	}

	img {
		@include absolute-full-width;
		object-fit: cover;
	}
}

.imaged-info__photo--full {
	@include mobile {
		@include aspect-ratio(440, 414);
	}
}

.imaged-info__nav {
	position: absolute;
	z-index: 50;
	bottom: 0;
}

.imaged-info__nav--left {
	left: 0;
	@include mobile {
		left: auto;
		right: 0;
	}
}

.imaged-info__nav--right {
	right: 0;
}

.imaged-info__description {
	right: $wrap-desk-lg;
	bottom: -42px;
	width: 620px;
	height: 630px;
	display: flex;
	align-items: center;
	background-color: $c-white;
	color: $c-dark;
	@include upTablet {
		position: absolute;
		z-index: 100;
	}
	@include upDesktopMaxi {
		bottom: -84px;
		width: 930px;
		height: 945px;
	}
	@include laptop {
		right: $wrap-desk-md;
		bottom: 0;
		transform: translateY(50%);
	}
	@include tabletMaxi {
		right: 0;
		transform: translateY(44%);
		width: percentage(670/1024);
		height: 588px;
	}
	@include mobile {
		transform: none;
		width: 100%;
		height: auto;
		min-height: 430px;
	}
}

.imaged-info__description--left {
	right: auto;
	left: $wrap-desk-lg;
	@include laptop {
		left: $wrap-desk-md;
	}
	@include onlyTabletMaxi {
		left: 0;
	}
}

.imaged-info__description--full {
	height: 700px;
	padding-top: 54px;
	@include upDesktopMaxi {
		height: 1050px;
	}
	@include tabletMaxi {
		transform: translateY(54%);
		height: 628px;
	}
	@include tablet {
		padding-top: 26px;
	}
	@include mobile {
		transform: none;
		height: auto;
		min-height: 500px;
		padding-top: 0px;
		padding-bottom: 15px;
	}
}

.imaged-info--full {
	&:nth-child(3n-2) {
		.imaged-info__description--full {
			@include media($b-laptop+1px) {
				bottom: -107px;
			}
			@include upDesktopMaxi {
				bottom: -214px;
			}
		}
	}

	&:nth-child(3n-1) {
		.imaged-info__description--full {
			@include media($b-laptop+1px) {
				bottom: -68px;
			}
			@include upDesktopMaxi {
				bottom: -102px;
			}
		}
	}

	&:nth-child(3n) {
		.imaged-info__description--full {
			@include media($b-laptop+1px) {
				bottom: -87px;
			}
			@include upDesktopMaxi {
				bottom: -130px;
			}
		}
	}
}

.imaged-info__description-wrapper {
	padding: 0 percentage(119/620) 0 percentage(81/620);
	@include tabletMaxi {
		padding: 0 percentage(104/670) 0 percentage(83/670);
	}
	@include mobile {
		padding: 80px $wrap-mob-md;
	}

	.imaged-info__description--left & {
		@include onlyTabletMaxi {
			padding: 0 percentage(83/670) 0 percentage(104/670);
		}
	}

	.imaged-info__description--full & {
		@include onlyTablet {
			padding: 0 10.5% 0 9.4%;
		}
		@include mobile {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}

	.imaged-info__description--full.imaged-info__description--left & {
		@include onlyTablet {
			padding: 0 9.4% 0 10.5%;
		}
	}
}

.imaged-info__title {
	margin-bottom: 20px;
	font-family: $font-accent;
	font-size: 50px;
	font-weight: 200;
	@include upDesktopMaxi {
		font-size: 70px;
	}
	@include tabletMaxi {
		margin-bottom: 28px;
	}
	@include mobile {
		margin-bottom: 25px;
		font-size: 42px;
	}
}

.imaged-info__title--full {
	@include mobile {
		font-size: 50px;
	}
}

.imaged-info__txt {
	@include upDesktopMaxi {
		font-size: 28px;
	}

	p:not(:last-child) {
		margin-bottom: 1.5em;
	}
}

.imaged-info__link {
	margin-top: 30px;
	@include upDesktopMaxi {
		height: 141px;
		padding-right: 63px;
		font-size: 36px;
	}

	&::after {
		@include upDesktopMaxi {
			width: 141px;
		}
	}

	svg {
		@include upDesktopMaxi {
			width: 15px;
			height: 25px;
		}
	}
}
