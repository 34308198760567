.home-rooms {
	position: relative;
	z-index: map-get($z-index, section);
}

.home-rooms__ticker {
	position: relative;
	z-index: 50;
	width: percentage(795/1620);
	height: vw(954/1620);
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $c-beige;
	color: #28281F;
	@include tabletMaxi {
		width: percentage(522/1024);
		height: vw(680/1024);
	}
	@include mobile {
		width: 100%;
		height: vw(620/414);
		padding-bottom: 16px;
	}
}

.home-rooms__content {
	position: relative;
	z-index: 50;
	width: percentage(380/795);
	@include tabletMaxi {
		width: percentage(314/522);
	}
	@include mobile {
		width: percentage(284/414);
	}
}

.home-rooms__photo {
	width: 100%;
	@include aspect-ratio(540, 380);
	@include tabletMaxi {
		@include aspect-ratio(454, 314);
	}
	@include mobile {
		@include aspect-ratio(440, 284);
	}

	img {
		@include absolute-full-width;
		object-fit: cover;
	}
}

[class*=home-rooms__label] {
	position: absolute;
	font-size: 18px;
	line-height: 1.1;
	font-weight: 300;
	text-transform: uppercase;
	@include upDesktopMaxi {
		font-size: 24px;
	}
	@include miniLaptop {
		font-size: 14px;
	}
	@include tabletMaxi {
		font-size: 18px;
	}
	@include tablet {
		font-size: 14px;
	}
	@include mobile {
		font-size: 18px;
	}
}

.home-rooms__label-top {
	left: 100%;
	top: 0;
	transform: rotate(90deg) translate(40%, 50%);
	@include tabletMaxi {
		transform: rotate(90deg) translate(47%,158%);
	}
	@include mobile {
		transform: rotate(90deg) translate(37%,139%);
	}
}

.home-rooms__label-bottom {
	right: 100%;
	bottom: 0;
	transform: rotate(-90deg) translate(41%, 25%);
	@include tabletMaxi {
		transform: rotate(-90deg) translate(48%,136%);
	}
	@include mobile {
		transform: rotate(-90deg) translate(40%,152%);
	}
}

.home-rooms__btn {
	position: absolute;
	z-index: 50;
	right: percentage(-27/380);
	bottom: percentage(80/540);
	width: percentage(110/380);
	@include aspect-ratio;
	border-radius: 50%;
	background-color: #28281F;
	font-family: $font-accent;
	text-align: center;
	font-size: 20px;
	line-height: 1.1;
	font-weight: 200;
	color: $c-beige;
	@include upDesktopMaxi {
		font-size: 28px;
	}
	@include miniLaptop {
		font-size: 16px;
	}
	@include tabletMaxi {
		right: percentage(104/314);
		bottom: percentage(-48/454);
		width: percentage(110/314);
		font-size: 20px;
	}
	@include tablet {
		font-size: 16px;
	}
	@include mobile {
		right: percentage(88/284);
		bottom: percentage(-55/440);
		width: percentage(110/284);
		font-size: 20px;
	}

	span {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.home-rooms__marquee-wrapper {
	@include absolute-full-width;
	overflow: hidden;
}

.home-rooms__marquee {
	position: absolute;
	left: 0;
	top: 49%;
	transform: translate(0, -50%);
	width: vw(795/1620);
	display: flex;
	@include mobile {
		top: 48%;
	}
}

.home-rooms__marquee--inner {
	top: 48.3%;
	transform: translate(-26%, -50%);
	@include tabletMaxi {
		transform: translate(-21%,-50%);
	}
	@include mobile {
		top: 49%;
		transform: translate(-33%,-50%)
	}

	svg {
		fill: $c-beige;
	}
}

.home-rooms__move {
	flex-shrink: 0;
	display: flex;

	svg {
		flex-shrink: 0;
		width: vw(489/1620);
		@include tabletMaxi {
			width: vw(385/1024);
		}
		@include mobile {
			width: vw(314/414);
		}
	}
}

.home-rooms__img {
	position: absolute;
	right: 0;
	top: vw(-95/1620);
	width: percentage(960/1620);
	height: calc(100% + #{vw(190/1620)});
	@include tabletMaxi {
		top: vw(-60/1024);
		width: percentage(669/1024);
		height: calc(100% + #{vw(120/1024)});
	}
	@include mobile {
		display: none;
	}
}
