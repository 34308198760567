.error-hero {
	position: relative;
	height: 100vh;
	padding: 0 $wrap-desk-sm;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: $c-black;
	@include tabletMaxi {
		padding: 0 40px;
	}
	@include mobile {
		padding: 0 $wrap-mob-md;
	}

	&::before {
		content: "";
	}
}

.error-hero__content {
	position: relative;
	z-index: 50;
	margin-top: 72px;
	text-align: center;
	@include tabletMaxi {
		margin-top: 47px;
	}
	@include mobile {
		margin-top: 66px;
	}
}

.error-hero__title {
	margin-bottom: -5px;
	font-family: $font-accent;
	font-size: 300px;
	line-height: 1;
	font-weight: 200;
	@include lowHeightLaptop {
		font-size: 170px;
	}
	@include mobile {
		font-size: 170px;
	}
	@include mobileMicro {
		font-size: 150px;
	}
}

.error-hero__subtitle {
	margin-bottom: 39px;
	font-family: $font-accent;
	font-size: 34px;
	line-height: 1.1;
	font-weight: 200;
	@include lowHeightLaptop {
		font-size: 28px;
	}
	@include mobile {
		margin-bottom: 30px;
	}
	@include mobileMicro {
		font-size: 28px;
	}
}

.error-hero__btn {
	width: 300px;
	@include lowHeightLaptop {
		height: 70px;
	}
	@include mobileMicro {
		height: 70px;
	}
}

.error-hero__bg {
	@include absolute-background;
	opacity: .6;
}
