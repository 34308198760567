.faqs {
	position: relative;
	overflow: hidden;

	.svg-logo-plant {
		position: absolute;
		right: -27%;
		bottom: 0;
		transform: rotate(-40deg) translateY(38%);
		max-width: none;
		width: percentage(1786/1620);
		opacity: 0.02;
		@include tabletMaxi {
			right: -60%;
			transform: rotate(-40deg) translateY(24%);
			width: percentage(1786/1024);
		}
		@include mobile {
			right: -34%;
			transform: rotate(-40deg) translateY(52%);
			width: percentage(760/414);
		}
	}
}

.faqs--faqs {
	padding-top: 164px;
	padding-bottom: 122px;
	@include tabletMaxi {
		padding-top: 154px;
		padding-bottom: 92px;
	}
	@include mobile {
		padding-top: 78px;
		padding-bottom: 62px;
	}
}

.faqs--services {
	padding-top: 431px;
	padding-bottom: 162px;
	@include tabletMaxi {
		padding-top: 376px;
		padding-bottom: 80px;
	}
	@include mobile {
		padding-top: 211px;
		padding-bottom: 60px;
	}
}

.faqs--careers {
	padding-bottom: 101px;
	@include tabletMaxi {
		padding-bottom: 81px;
	}
	@include mobile {
		padding-bottom: 41px;
	}
}

.faqs__wrapper {
	position: relative;
	z-index: 50;
	padding: 0 $wrap-desk-sm;
	@include tabletMaxi {
		padding: 0 $wrap-tab-md;
	}
	@include mobile {
		padding: 0 $wrap-mob-md;
	}
}

.faqs__container,
.faq__container {
	@extend %container;
	max-width: 1460px;
}

.faqs__title {
	margin-bottom: 49px;
	@include tabletMaxi {
		margin-bottom: 56px;
	}
	@include mobile {
		margin-bottom: 42px;
	}
}

%faq-pseudo {
	content: "";
	position: absolute;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: currentColor;
	opacity: .12;
	transition: opacity $ease-500;
	@include mobile {
		left: -$wrap-mob-md;
		width: calc(100% + #{$wrap-mob-md * 2});
	}

	.is-open & {
		opacity: 0;
	}
}

.faq {
	position: relative;
	z-index: 50;
	padding: 0 $wrap-desk-sm;
	transition: background-color $ease-500;
	@include tabletMaxi {
		padding: 0 $wrap-tab-sm;
	}
	@include mobile {
		padding: 0 $wrap-mob-md;
	}

	&:last-child {
		.faq__container {
			&::after {
				@extend %faq-pseudo;
				bottom: 0;
			}
		}
	}

	&.is-open {
		background-color: $c-green;
	}
}

.faq__container {
	position: relative;

	&::before {
		@extend %faq-pseudo;
		top: 0;
	}
}

.faq__button {
	padding: 42px 20px 47px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include tabletMaxi {
		padding-right: 0;
	}
	@include mobile {
		padding-top: 23px;
		padding-bottom: 26px;
	}
}

.faq__title {
	font-family: $font-accent;
	font-size: 34px;
	font-weight: 200;
	@include mobile {
		font-size: 21px;
	}
}

.faq__icon {
	margin-left: 20px;
	flex: none;
	@include mobile {
		width: 50px;
		height: 50px;
	}

	svg {
		width: 25px;
		height: 25px;
		@include mobile {
			width: 21px;
			height: 21px;
		}
	}

	.icon-minus {
		display: none;
	}

	.is-open & {
		.icon-plus {
			display: none;
		}

		.icon-minus {
			display: block;
		}
	}
}

.faq__details {
	&::before {
		content: "";
		display: block;
		margin-bottom: 53px;
		height: 1px;
		background-color: currentColor;
		@include tabletMaxi {
			margin-bottom: 103px;
		}
		@include mobile {
			margin-bottom: 35px;
		}
	}

	&::after {
		content: "";
		display: block;
		margin-top: 123px;
		@include tabletMaxi {
			margin-top: 157px;
		}
		@include mobile {
			margin-top: 66px;
		}
	}
}

.faq__text,
.faq__link {
	margin-left: percentage(500/1460);
	@include tabletMaxi {
		margin-left: percentage(335/984);
	}
	@include mobile {
		margin-left: 0;
	}
}

.faq__text {
	width: percentage(700/1460);
	@include tabletMaxi {
		width: percentage(565/984);
	}
	@include mobile {
		width: 100%;
	}

	p {
		&:not(:last-child) {
			margin-bottom: 37px;
			@include mobile {
				margin-bottom: 30px;
			}
		}
	}
}

.faq__link {
	margin-top: 29px;
	@include tabletMaxi {
		margin-top: 43px;
		margin-bottom: -24px;
	}
	@include mobile {
		margin-top: 35px;
		margin-bottom: 42px;
	}
}
