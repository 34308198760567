.imaged-description {
	display: flex;
	flex-direction: column;

	+ .imaged-description {
		margin-top: 120px;
		@include tabletMaxi {
			margin-top: 60px;
		}

		&.imaged-description--logo {
			@include mobile {
				margin-top: 0;
			}
		}
	}

	&:last-child {
		margin-bottom: 140px;
		@include tabletMaxi {
			margin-bottom: 86px;
		}
		@include mobile {
			margin-bottom: 0;
		}
	}
}

.imaged-description__wrapper {
	position: relative;
	padding: 0 $wrap-desk-lg;
	@include laptop {
		padding: 0 $wrap-desk-md;
	}
	@include tabletMaxi {
		padding: 0 $wrap-tab-md;
	}
	@include mobile {
		order: 1;
		padding: 0 $wrap-mob-md;
	}

	&::before {
		content: "";
		position: absolute;
		z-index: 10;
		left: 0;
		top: 0;
		width: calc(100% - #{$wrap-desk-lg});
		height: calc(100% + #{vw(520/1620)});
		background-color: $c-green;
		@include laptop {
			width: calc(100% - #{$wrap-desk-md});
		}
		@include tabletMaxi {
			width: 100%;
			height: calc(100% + #{vw(287/1024)});
		}
		@include mobile {
			height: 100%;
		}
	}

	.description + .imaged-description & {
		@include upLaptop {
			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: -1px;
				width: 100%;
				height: calc(100% + #{vw(520/1620)} + 1px);
				background-color: $c-beige;
			}
		}
	}
}

.imaged-description__wrapper--left {
	&::before {
		left: auto;
		right: 0;
	}
}

.imaged-description__wrapper--dark-green {
	&::before {
		background-color: $c-dark-green;
	}
}

.imaged-description__container {
	@extend %container;
	position: relative;
	z-index: 50;
	max-width: 1260px;
	padding-top: 156px;
	padding-bottom: 49px;
	display: flex;
	justify-content: space-between;
	@include tabletMaxi {
		padding: 89px 0 53px 5px;
	}
	@include tablet {
		flex-direction: column;
	}
	@include mobile {
		height: 520px;
		padding: 31px 0 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.imaged-description__container--logo {
	@include upLaptop {
		padding-top: 145px;
		padding-bottom: 57px;
	}
	@include mobile {
		height: 540px;
	}
}

.imaged-description__container--large-logo {
	@include upLaptop {
		padding-top: 123px;
		padding-bottom: 72px;
	}
	@include mobile {
		height: 560px;
	}
}

.imaged-description__left {
	@include upLaptop {
		width: percentage(561/1260);
	}
	@include media($min: $b-tablet+1px, $max: $b-tablet-maxi) {
		width: percentage(471/849);
	}
}

.imaged-description__left--left {
	@include upLaptop {
		margin-left: 9%;
	}
	@include m-up($b-laptop) {
		margin-left: percentage(160/1260);
	}
}

.imaged-description__left--logo {
	@include media($min: $b-tablet+1px, $max: $b-tablet-maxi) {
		width: percentage(431/849);
	}
}

.imaged-description__title {
	margin-top: -5px;
	font-family: $font-accent;
	font-size: 70px;
	font-weight: 200;
	@include tabletMaxi {
		margin-top: 2px;
		font-size: 60px;
	}
	@include tablet {
		margin-top: 0;
		margin-bottom: 20px;
	}
	@include mobile {
		margin-bottom: 15px;
		font-size: 52px;
	}
	@include mobileMicro {
		font-size: 47px;
	}
}

.imaged-description__logo-title {
	@include onlyTabletMaxi {
		margin-top: 13px;
	}
	@include mobile {
		margin-bottom: 56px;
	}
}

.imaged-description__logo-title--large {
	@include tabletMaxi {
		margin-top: 0;
	}
}

.imaged-description__right {
	@include upLaptop {
		width: percentage(539/1260);
	}
	@include media($min: $b-tablet+1px, $max: $b-tablet-maxi) {
		width: percentage(378/849);
	}

	&:not(.imaged-description__right--left) {
		@include upLaptop {
			margin-right: 8%;
		}
		@include m-up($b-laptop) {
			margin-right: percentage(150/1260);
		}
	}
}

.imaged-description__right--logo {
	@include media($min: $b-tablet+1px, $max: $b-tablet-maxi) {
		width: percentage(418/849);
	}
}

.imaged-description__txt {
	margin-bottom: 25px;
	@include tabletMaxi {
		margin-bottom: 26px;
	}
}

.imaged-description__gallery {
	z-index: 100;
	margin-left: auto;
	margin-right: 0;
	width: percentage(1200/1620);
	@include laptop {
		width: 80%;
	}
	@include tabletMaxi {
		margin-left: 0;
		margin-right: auto;
		width: calc(100% - #{$wrap-tab-lg});
	}
	@include mobile {
		width: 100%;
	}
}

.imaged-description__gallery--left {
	@include upTablet {
		margin-left: 0;
		margin-right: auto;
	}
}

.imaged-description__gallery--desktop-left {
	@include upLaptop {
		margin-left: 0;
		margin-right: auto;
	}
}

.imaged-description__photo {
	@include aspect-ratio(800, 1200);
	transition-property: opacity;
	@include tabletMaxi {
		@include aspect-ratio(587, 920);
	}
	@include mobile {
		@include aspect-ratio(441, 414);
	}

	img {
		@include absolute-full-width;
		object-fit: cover;
	}
}

.imaged-description__nav {
	position: absolute;
	z-index: 150;
	bottom: 0;
}

.imaged-description__nav--left {
	left: 0;
	@include mobile {
		left: auto;
		right: 0;
	}
}

.imaged-description__nav--right {
	right: 0;
}
