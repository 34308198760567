// Input styling
%input-style {
	display: block;
	width: 100%;
	height: 80px;
	border: 1px solid rgba($c-beige, .2);
	padding: 0 30px;
	font-family: $font-accent;
	font-size: 17px;
	font-weight: 200;
	text-transform: uppercase;
	color: currentColor;
	@include mobile {
		height: 76px;
		padding: 0 25px;
		text-transform: none;
	}

	.form--dark & {
		border-color: rgba($c-dark, .3);
	}

	.form--error & {
		border-color: rgba($c-error, .4);
		color: $c-error;
	}
}

#{$input-txt} {
	@extend %input-style;
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	&[disabled] {
		// styles
		opacity: 1; // fix for safari
	}
}

textarea {
	overflow: auto;
	resize: none;
	height: 180px;
	padding-top: 30px;
	padding-bottom: 30px;
}

// Checkbox and Radio styling

.checkbox,
.radio {
	position: relative;

	input {
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
	}

	label {
		cursor: pointer;

		&::before {
			content: "";
			// styles for Box
		}

		&::after {
			content: "";
			// styles for Check in unchecked state
		}
	}

	input:checked + label {

		&::after {
			// styles for Check in checked state
		}
	}
}

// Range input styling
@mixin rangeThumb {
	// thumb's styles
	cursor: pointer;
}

@mixin rangeTrack {
	// track's styles
}

.range-input {
	-webkit-appearance: none;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		margin-top: -7px;

		@include rangeThumb;
	}

	&::-moz-range-thumb {
		@include rangeThumb;
	}

	&::-ms-thumb {
		@include rangeThumb;

		margin-top: 0;
	}

	&::-webkit-slider-runnable-track {
		@include rangeTrack;
	}

	&::-moz-range-track {
		@include rangeTrack;
	}

	&::-ms-track {
		width: 100%;
		color: transparent;
		cursor: pointer;
		background: transparent;
		border-color: transparent;

		@include rangeTrack;
	}
}
