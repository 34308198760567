.imaged-details {
	position: relative;

	&:nth-child(2n-1) {
		.imaged-details__gallery {
			margin-left: 0;
		}

		.imaged-details__nav {
			left: 0;
			@include mobile {
				left: auto;
				right: 0;
			}
		}

		.imaged-details__info {
			right: 0;
			@include onlyTabletMaxi {
				bottom: -96px;
			}
			@include onlyTablet {
				bottom: -275px;
			}
		}
	}

	&:nth-child(2n) {
		.imaged-details__gallery {
			margin-right: 0;
		}

		.imaged-details__nav {
			right: 0;
		}

		.imaged-details__info {
			left: 0;
			@include onlyTabletMaxi {
				bottom: -275px;
				padding-left: 105px;
			}
			@include onlyTablet {
				padding-left: 75px;
			}
		}
	}

	&:not(:last-child) {
		margin-bottom: 96px;
		@include mobile {
			margin-bottom: 0;
		}

		&:nth-child(2n-1) {
			@include onlyTabletMaxi {
				margin-bottom: 164px;
			}
			@include onlyTablet {
				margin-bottom: 346px;
			}
		}

		&:nth-child(2n) {
			@include onlyTabletMaxi {
				margin-bottom: 346px;
			}
		}
	}
}

.imaged-details__gallery {
	width: percentage(859/1420);
	@include miniLaptop {
		width: 72%;
	}
	@include tabletMaxi {
		width: percentage(837/1024);
	}
	@include mobile {
		width: 100%;
	}
}

.imaged-details__photo {
	position: relative;
	@include aspect-ratio(664, 859);
	transition-property: opacity;
	@include tabletMaxi {
		@include aspect-ratio(680, 837);
	}
	@include mobile {
		@include aspect-ratio(400, 414);
	}

	img {
		@include absolute-full-width;
		object-fit: cover;
	}
}

.imaged-details__nav {
	position: absolute;
	z-index: 50;
	bottom: 0;
}

.imaged-details__info {
	position: absolute;
	z-index: 50;
	top: 50%;
	transform: translateY(-50%);
	width: percentage(700/1420);
	height: 480px;
	padding: 16px 80px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	background-color: $c-white;
	@include miniLaptop {
		width: 51%;
		padding: 16px 55px 0;
	}
	@include tabletMaxi {
		top: auto;
		transform: none;
		width: percentage(586/1024);
		height: 464px;
		padding: 16px 85px 0;
	}
	@include tablet {
		width: 66%;
		padding: 16px 55px 0;
	}
	@include mobile {
		position: static;
		width: 100%;
		height: 490px;
		padding: 28px $wrap-mob-md 0;
	}
}

.imaged-details__upper-title {
	margin-bottom: 25px;
	@include onlyTabletMaxi {
		margin-bottom: 19px;
	}
}

.imaged-details__title {
	margin-bottom: 25px;
	font-family: $font-accent;
	font-size: 40px;
	line-height: (43/42);
	font-weight: 400;
	@include tabletMaxi {
		margin-bottom: 19px;
		font-weight: 200;
	}
	@include mobile {
		font-size: 42px;
	}
}

.imaged-details__txt {
	margin-bottom: 30px;
	max-width: 420px;
	@include tabletMaxi {
		max-width: 400px;
	}
	@include mobile {
		margin-bottom: 35px;
	}
}
