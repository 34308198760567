.modal__overlay {
	position: fixed;
	z-index: map-get($z-index, overlay);
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($c-dark, .6);
	transition: opacity $ease-300;
}

.modal {
	position: absolute;
	z-index: map-get($z-index, modal);
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	max-width: calc(100% - #{$wrap-mob-md * 2});
	width: 620px;
	display: flex;
	background-color: $c-white;
	color: $c-dark;
	@include portrait {
		flex-direction: column;
	}

	&:not(.modal--full) {
		@media (max-width: $b-mobile-midi+1px) and (min-height: 761px)  {
			top: calc(50% + 33px);
		}
	}
}

.modal--full {
	@include landscape {
		width: 1100px;
	}
	@include media($b-mobile+1px, $b-tablet-maxi, portrait) {
		top: calc(50% + 5px);
	}
}

.modal__photo {
	position: relative;
	@include landscape {
		width: 50%;
	}
	@include portrait {
		height: 390px;
	}
	@include mobile {
		height: 310px;
	}
	@media (max-width: $b-mobile-midi+1px) and (max-height: 760px) {
		height: 210px;
	}

	img {
		@include absolute-full-width;
		object-fit: cover;
	}
}

.modal__message {
	width: 100%;
	min-height: 510px;
	padding: 75px 50px 98px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	@include lowHeightLaptop {
		padding-top: 55px;
		padding-bottom: 55px;
	}
	@include portrait {
		min-height: 470px;
		padding-top: 94px;
		padding-bottom: 92px;
	}
	@include mobile {
		min-height: 520px;
		padding: 80px $wrap-mob-md 90px;
	}

	.modal__photo + & {
		@include landscape {
			width: 50%;
		}
	}

	.modal--full & {
		padding: 82px 65px 91px;
		@include lowHeightLaptop {
			padding: 55px 50px;
		}
		@include portrait {
			min-height: 450px;
			padding-top: 64px;
			padding-bottom: 95px;
		}
		@include mobile {
			min-height: 380px;
			padding: 30px $wrap-mob-md 48px;
		}
	}
}

.modal__title {
	position: relative;
	margin-bottom: 39px;
	font-family: $font-accent;
	font-size: 40px;
	line-height: (44/40);
	font-weight: 200;
	@include tabletMaxi {
		margin-bottom: 36px;
		font-size: 32px;
	}
	@include mobile {
		margin-bottom: 44px;
	}

	&::after {
		content: "";
		position: absolute;
		left: calc(50% - 20px);
		top: calc(100% + 19px);
		width: 40px;
		height: 1px;
		background-color: rgba($c-green, .4);
	}

	.modal--full & {
		@include tabletMaxi {
			margin-bottom: 45px;
		}
		@include mobile {
			margin-bottom: 40px;
			font-size: 30px;
		}
	}
}

.modal__txt {
	margin-bottom: 34px;
	font-family: $font-accent;
	font-size: 19px;
	line-height: (28/19);
	font-weight: 200;
	@include tabletMaxi {
		margin-bottom: 37px;
	}
	@include mobile {
		margin-bottom: 42px;
		font-size: 17px;
		line-height: (26/17);
	}

	.modal--full & {
		margin-bottom: 28px;
	}
}

.modal__close {
	position: absolute;
	z-index: 100;
	right: 20px;
	top: 20px;
	@include mobile {
		right: 10px;
		top: 10px;
	}

	.modal--full & {
		@include portrait {
			background-color: $c-white;
		}
	}
}
